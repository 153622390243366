import React, { Component, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import { db } from "./firebase";
import { Timestamp, doc, setDoc } from "firebase/firestore";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<TitiHome />} />
        <Route path="/:voterID" element={<TitiVoter />} />
      </Routes>
    </Router>
  );
}

function TitiHome() {
  useEffect(() => {
    window.location.replace("https://www.tititijani.com");
  }, []);

  return (
    <center>
      <h4>Titi Tijani</h4>
      <h6>Redirecting</h6>
    </center>
  );
}

function TitiVoter() {
  let { voterID } = useParams();
  let calledVoterID = false;

  useEffect(() => {
    if (!calledVoterID) {
      let formattedVoterID = new Number(voterID) / 987656789;
      calledVoterID = true;
      setDoc(doc(db, `qr_code_undecided/${formattedVoterID}`), {
        voter_id: formattedVoterID,
        opened_at: Timestamp.now(),
      }).then(() => {
        window.location.replace("https://www.tititijani.com");
      });
    }
  }, []);

  return (
    <center>
      <h4>Titi Tijani</h4>
      <h6>Redirecting</h6>
    </center>
  );
}

export default App;
