// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCwCoJ3Q1LQQirPfVHIXk7ibf3kdaAGikA",
  authDomain: "titi-tijani-campaign.firebaseapp.com",
  projectId: "titi-tijani-campaign",
  storageBucket: "titi-tijani-campaign.appspot.com",
  messagingSenderId: "1098678115554",
  appId: "1:1098678115554:web:95f3c3051b60fd38ce52bb",
  measurementId: "G-DM4FYW69HM",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
